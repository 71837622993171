// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-gallery-article-js": () => import("/opt/build/repo/src/templates/galleryArticle.js" /* webpackChunkName: "component---src-templates-gallery-article-js" */),
  "component---src-templates-review-js": () => import("/opt/build/repo/src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-pages-home-page-js": () => import("/opt/build/repo/src/pages/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

